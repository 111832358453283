<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button :to='{name: "products"}' variant="primary" size="sm">
          Back to Products
        </b-button>
        <b-button @click="remove()" variant="danger" size="sm" class="ml-1">
          Delete
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card no-body v-if="!productIsLoading && product">
        <b-tabs card>
          <b-tab title="Product" active>
            <ProductForm :product="product"/>
          </b-tab>
          <b-tab title="Prices" lazy>
            <ProductPrices v-if="product.upgradable" :product="product"/>
            <span v-else>Not applicable because the product is not upgradable</span>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      ProductForm: () => import('./../../components/Product.Form'),
      ProductPrices: () => import('./../../components/Product.Prices'),
    },
    data(){
      return {
        page: {
            title: "Loading...",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Product Management",
                url: this.$router.resolve({name: "products"}).href
            }
        ],
        product: null,
        productIsLoading: false,
      }
    },
    methods: {
      getProduct(productId){
        this.productIsLoading = true;
        return this.$api.get(`product/${productId}`).then(({data}) => {
          this.product = data;
          this.productIsLoading = false;
          this.page.title = this.product.name;
          return this.product;
        });
      },

      remove(){
        if(confirm("Are you sure you want to delete this product?")){
          this.$api.delete(`product/${this.product.id}`).then(() => {
            this.$router.push({name: "products"});
          });
        }
      }
    },
    mounted(){
      this.getProduct(this.$route.params.productId).then(product => {
        this.product = product;
      });
      this.$emit('set-sidebar',{active: "products"});
    },
};
</script>
